export const URL_HOME = '/home'
export const URL_LEADERBOARD = '/leaderboard'
export const URL_LEADERBOARD_COPY = '/leaderboard?tab=copyleader'
export const URL_LEADERBOARD_LEAGUE = '/leaderboard?tab=league'
export const URL_PORTFOLIO = (user_id: string) => `/user/${user_id}/portfolio`
export const URL_KEY_PORTFOLIO = (key_id: string) => `/key/${key_id}/portfolio`
export const URL_KEY_OPEN_ORDER = (key_id: string) => `/key/${key_id}/open-order`
export const URL_KEY_HISTORY = (key_id: string) => `/key/${key_id}/history`
export const URL_KEY_EARNING = (key_id: string) => `/key/${key_id}/pnl`
export const URL_COPY_PORTFOLIO = (user_id: string) => `/user/${user_id}/copyfolio`
export const URL_BOT_EARNING = (bot_id: string, key_id: string) => `/bot/${bot_id}/pnl?key_id=${key_id}`
export const URL_BOT_HISTORY = (user_id: string) => `/user/${user_id}/copyfolio/history`
export const URL_LEADER_PORTFOLIO = (user_id: string) => `/user/${user_id}/leaderfolio`
export const URL_LEADER_PAYMENT = (user_id: string) => `/user/${user_id}/leaderfolio/payment`
export const URL_LEADER_COPIERS = (key_id: string) => `/key/${key_id}/leaderfolio/copiers`
export const URL_LEADER_HISTORY = (key_id: string) => `/key/${key_id}/leaderfolio/history`
export const URL_LEADER_EARNING = (key_id: string) => `/key/${key_id}/leaderfolio/pnl`
export const URL_DOWNLOAD = '/download'
export const URL_ANNOUNCE = '/announce'

export const URL_TOKEN = '/token'
export const URL_CHART = '/chart'
export const URL_MEME = '/meme'

export const URL_AI = '/ai'

export const URL_ANNOUNCE_POST = (announce_id: string, category?: string, page?: number) => {
    if (category && page <= 1) {
        return `/announce/${announce_id}?category=${category}`
    }
    if (page > 1 && !category) {
        return `/announce/${announce_id}?page=${page}`
    }
    if (category && page > 1) {
        return `/announce/${announce_id}?category=${category}&page=${page}`
    }
    return `/announce/${announce_id}`
}
export const URL_POST = (post_id: string) => `/post/${post_id}`
export const URL_ACCOUNT_SETTING = '/account/setting'
export const URL_ACCOUNT_DELETE = '/account/delete'
export const URL_ACCOUNT_API = '/account/api'
export const URL_ACCOUNT_API_CREATE = '/account/api/create'
export const URL_ACCOUNT_API_REGISTER = '/account/api/register'
export const URL_ACCOUNT_WALLET = '/account/wallet'
export const URL_ACCOUNT_WITHDRAW = '/account/wallet/withdraw'
export const URL_ACCOUNT_WITHDRAW_COMPLETE = '/account/wallet/withdraw/complete'
export const URL_ACCOUNT_WITHDRAW_CONFIRM = '/account/wallet/withdraw/confirm'
export const URL_ACCOUNT_WITHDRAW_EMAIL = '/account/wallet/withdraw/email'
export const URL_ACCOUNT_WALLET_HISTORY = '/account/wallet/history'
export const URL_ACCOUNT_DEPOSIT = '/account/wallet/deposit'
export const URL_ACCOUNT_2FA_REGISTER = '/account/wallet/2fa/setup/register'
export const URL_ACCOUNT_2FA_EMAIL = '/account/wallet/2fa/setup/email'
export const URL_ACCOUNT_2FA_COMPLETE = '/account/wallet/2fa/setup/complete'
export const URL_ACCOUNT_INVOICE = '/account/invoice'
export const URL_ACCOUNT_INVOICE_DETAIL = (invoice_id: string) => `/account/invoice/${invoice_id}`
export const URL_ACCOUNT_SUBSCRIBER = '/account/subscriber'
export const URL_ACCOUNT_NOTIFICATION = '/account/notification'
export const URL_ACCOUNT_REFERRAL = '/account/referral'
export const URL_ACCOUNT_REFERRAL_RULE = '/account/referral/rule'
export const URL_ACCOUNT_REFERRAL_HISTORY = '/account/referral/history'
export const URL_ACCOUNT_POINT = '/account/point'
export const URL_ACCOUNT_LANDING_PRO = '/landing/pro'
export const URL_ACCOUNT_PRO = '/account/pro'
export const URL_ACCOUNT_PRO_PAYMENT = '/account/pro/payment'
export const URL_ACCOUNT_TERM_PRIVACY = '/terms/privacy'
export const URL_ACCOUNT_TERM_CONDITIONS = '/terms/terms-and-conditions'
export const URL_ACCOUNT_TERM_LIVE = '/terms/live-policy'
export const URL_ACCOUNT_TERM_POINT = '/terms/point'

export const URL_BINANCE_FAST_API =
    'https://accounts.binance.com/en/oauth/authorize?response_type=code&scope=account:status,create:apikey,user:openId&client_id=BzitAySCIo&redirect_uri=https://leagueoftraders.io/redirect/auth/binance/login'

export const URL_COINBASE_FAST_API =
    'https://www.coinbase.com/oauth/authorize?account=all&client_id=72c3c13e581c201007bd395b1769e130c5655481575d2fcde023cd1907efd67e&redirect_uri=https%3A%2F%2Fleagueoftraders.io%2Flogin%2Fcoinbase&response_type=code&scope=wallet%3Aaccounts%3Aread%2Cwallet%3Aaddresses%3Aread%2Cwallet%3Abuys%3Aread%2Cwallet%3Adeposits%3Aread%2Cwallet%3Awithdrawals%3Aread%2Cwallet%3Anotifications%3Aread%2Cwallet%3Asells%3Aread%2Cwallet%3Atransactions%3Aread%2Cwallet%3Auser%3Aread%2Cwallet%3Auser%3Aemail'

export const URL_MEME_TEST_URL = 'https://memetest.leagueoftraders.io'

export const getLinkUrl = (url: string, query?: object) => {
    if (query) {
        const str = Object?.keys(query)?.map(key => `${key}=${query[key]}`)
        if (str?.length > 0) {
            const result = str?.join('&')
            return `${url}?${result}`
        } else {
            return url
        }
    } else {
        return url
    }
}
