import {IMe} from '@type/profile'

export type NatsChatChannel = 'ko' | 'en'
export type NatsTrendsType = 'coin' | 'user'

export const NatsSubjects = {
    OrderSub: 'public.order.>',
    NotificationsSub: (user_id: string) => `user.${user_id}.notifications.>`,
    ChatSub: (channel: NatsChatChannel) => `public.chat.${channel}`,
    ChatPub: (channel: NatsChatChannel, userId: string) => `private.chat.${channel}.${userId}`,
    TrendsSub: 'public.trends.rising.>',
}

export const BucketNames = {
    UserInfo: 'user_info',
    LiveChart: 'live_chart',
} as const

export const WatchKeys = {
    Indicator: 'indicator',
    HighVolumeOrder: 'high_volume_order',
} as const

export type TBucketUserInfo = Pick<IMe, 'name' | 'thumbnail' | 'pro_until'>
